exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atsisakyti-js": () => import("./../../../src/pages/atsisakyti.js" /* webpackChunkName: "component---src-pages-atsisakyti-js" */),
  "component---src-pages-atsisakyti-prenumeratos-js": () => import("./../../../src/pages/atsisakyti-prenumeratos.js" /* webpackChunkName: "component---src-pages-atsisakyti-prenumeratos-js" */),
  "component---src-pages-forma-issiusta-js": () => import("./../../../src/pages/forma-issiusta.js" /* webpackChunkName: "component---src-pages-forma-issiusta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klausimas-pateiktas-js": () => import("./../../../src/pages/klausimas-pateiktas.js" /* webpackChunkName: "component---src-pages-klausimas-pateiktas-js" */),
  "component---src-pages-patvirtinti-prenumerata-js": () => import("./../../../src/pages/patvirtinti-prenumerata.js" /* webpackChunkName: "component---src-pages-patvirtinti-prenumerata-js" */),
  "component---src-pages-uzsakymas-priimtas-js": () => import("./../../../src/pages/uzsakymas-priimtas.js" /* webpackChunkName: "component---src-pages-uzsakymas-priimtas-js" */),
  "component---src-pages-zinute-issiusta-js": () => import("./../../../src/pages/zinute-issiusta.js" /* webpackChunkName: "component---src-pages-zinute-issiusta-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

