export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)
  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]))
  }, 0)
  return false
}

export const onInitialClientRender = () => {
  setTimeout(function () {
    document.body.classList.add("loaded")
  }, 100)
}
